import PropTypes from 'prop-types'

import * as S from './BaseDocumentaire.styles'

export const BaseDocumentaire = ({ contentComponent, title }) => {
  return (
    <S.Page>
      <S.Content>
        <S.Title>{title}</S.Title>

        {contentComponent}
      </S.Content>
    </S.Page>
  )
}

BaseDocumentaire.propTypes = {
  contentComponent: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}
