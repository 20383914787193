import { usePostContent } from '@pretto/website/src/postTypes/context/PostContent'
import { BaseDocumentaire as BaseDocumentaireView } from '@pretto/website/src/templates/baseDocumentaire/views/BaseDocumentaire/BaseDocumentaire'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { cloneElement } from 'react'

export const BaseDocumentaire = ({ data }) => {
  const { contentComponent } = usePostContent()

  return (
    <BaseDocumentaireView
      contentComponent={cloneElement(contentComponent, { attachments: data.template.documentary.attachments })}
      title={data.title}
    />
  )
}

BaseDocumentaire.propTypes = {
  data: PropTypes.shape({
    template: PropTypes.shape({
      documentary: PropTypes.shape({
        attachments: PropTypes.arrayOf(
          PropTypes.shape({
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
          })
        ).isRequired,
      }).isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export const fragment = graphql`
  fragment BaseDocumentaireTemplate on WpTemplate_BaseDocumentaire {
    documentary {
      attachments: models {
        slug
        title
      }
    }
  }
`
